import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import PageWrap from '../components/pageWrap'
import P from '../components/paragraph'
import {StaticQuery, graphql} from 'gatsby'

const ProjectsPage = () => (
  <StaticQuery
    query={graphql`
      query {
        ProjectPageImage: file(relativePath: { eq: "cityscape-two.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2880, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader image={data.ProjectPageImage.childImageSharp}>
          Projects
        </PageHeader>
        <PageWrap>
          <h2>
            SPAWAR Systems Center Pacific: TCAD – Tactical Cyber Attack
            Deterrence
          </h2>
          <P>
            Tactical environments have frequent personnel changes and computing
            skills are limited. These systems must be resilient and continue to
            operate even under attack. The damage done should be kept to
            minimum, preferably the system should return to a well know good
            state as rapidly as possible.
          </P>
          <P>
            The field commanders computing assets, especially the data
            collection modules, accepts inputs from a variety of sources,
            including some that have not been vetted. Thus protecting these
            computing assets in theater is a challenge.
          </P>
          <P>
            The current cyber defense approach relies on knowledge of the
            attacker’s approach and system vulnerabilities. The proposed moving
            target defense continuously transforms the attack surface and
            protects assets from long term damage. We use a moving target
            defense to both deter and automatically recover from an attack. We
            have already employed this technique to reduce the exposure times of
            servers and allowing them to automatically recover from certain
            types of attacks. In addition to reducing exposure time, we will
            diminish the attack vectors by disrupting the ability for the
            adversary to observe, orient and decide on an attack.
          </P>
        </PageWrap>
        <PageWrap>
          <h2>
            National Library of Medicine: Personal Health Record Project
            (Subcontract)
          </h2>
          <P>
            SCIT Labs personnel are actively supporting the Personal Health
            Record (PHR) Project of the Lister Hill National Center for
            Biomedical Communications, a division of the National Library of
            Medicine. Under the direction of the LHNCBC Director, the project is
            examining the implementation and effectiveness of personal health
            records. The PHR is a web-based tool for consumers to keep track of
            their own health information as well as that of their children and
            elderly or other dependents. It takes advantage of national coding
            and terminology standards and in the future will help serve as a
            bridge for meaningful data exchange between electronic health record
            systems. Security of personally identifiable information is
            maintained in all aspects of the system, including via secure coding
            methods, physical storage security, and restricted access to user
            data.
          </P>
          <P>
            Although the PHR is not yet live, NLM is in the final stages of
            contract negotiations with a local hospital that has agreed to host
            the PHR. For a preview, visit{' '}
            <a href="https://phr-demo.nlm.nih.gov" taget="blank" rel="noopener">
              https://phr-demo.nlm.nih.gov/
            </a>
          </P>
        </PageWrap>
        <PageWrap>
          <h2>
            DHS: Scalable MTD [Moving Target Defense] Based on SCIT Technology
          </h2>
          <P>
            The increasing reliance of government, business and not-for-profit
            sector on computing infrastructure has provided our adversaries with
            an opportunity to gain advantage by launching organized, targeted,
            and coordinated attacks. The attackers have several motivations,
            including: (1) burglary: data theft related to intellectual property
            or personal information; (2) vandalism: leading to web site
            defacement and reduced availability on the internet; (3) extortion:
            using threats of DDOS, database encryption, or other mischief, bad
            guys can extort their cyber victims. SCIT Labs products focus on
            directly mitigating the effects of the first two – we reduce the
            opportunity for malicious ex-filtration of data, and limit the
            duration for which vandals are succeeding. We have an indirect
            impact on the extortion problem – making it difficult for the bad
            actors from completing the bad acts, e.g. encryption of the
            database. In this SBIR we plan to undertake research to support our
            product plan.
          </P>
          <P>
            The current approaches to cyber security are reactive and rely on
            our ability to identify the vulnerabilities in our systems and model
            attacker strategy. Detecting and preventing all attacks is hard and
            we believe nearly impossible. By slightly modifying their strategy
            the attacker can readily defeat the current defensive systems.
            Moreover, the manpower required to manage alerts, generated by the
            IDS/IPS and log analysis and reduced through a SIEM, is large and
            beyond the reach of even large corporations. To illustrate the
            complexity, we note that Symantec reports encountering 286 million
            unique variants of malware in 2010.
          </P>
          <P>
            SCIT – the digital vaccine is a new approach to the cyber security
            problem. We focus on server security. We have built systems that
            convert sitting duck static servers into more agile and dynamic
            systems. To demonstrate the scalability of SCIT, we have interfaced
            SCIT to HP Fortify Runtime and Guidance Software Encase forensics
            tool. The deployment of SCIT in an environment of many (thousands)
            of VMs is explored.
          </P>
        </PageWrap>
        <PageWrap>
          <h2>
            Department of Labor: Virtual Career Network - Healthcare
            (Subcontract)
          </h2>
          <P>
            Healthcare is among the fastest growing fields in the US economy and
            offers its workers good salaries and great opportunities for
            advancement. The VCN (Virtual Career Network Healthcare) is an “open
            source” workforce services and online learning delivery platform
            built and operated by the American Association of Community Colleges
            (AACC) under a grant from the Employment and Training Administration
            (ETA) of the United States Department of Labor (DOL). This website
            was developed to help US workers transition into healthcare careers.
            VCN (Healthcare) users can learn about healthcare careers, locate
            education and training programs, build a robust career portfolio,
            and search for jobs.
          </P>
          <P>
            SCIT Labs helped designed the Functional, System and Security
            Architecture of the VCN Platform. This involved setting up highly
            available cluster of servers that was designed to be robust and
            secure. Since Personally Identifiable Information (PII) was being
            stored as part of the platform, it was critical to ensure that all
            information was encrypted both in storage and transit.
          </P>
        </PageWrap>
        <PageWrap>
          <h2>
            National Library of Medicine: Lost Person Finder (Subcontract)
          </h2>
          <P>
            SCIT Labs supported development of the Lost Persons Finder (LPF)
            project during the Haiti Earthquake.
          </P>
        </PageWrap>
        <PageWrap>
          <h2>
            National Library of Medicine: Unified Medical Language System:
            Knowledge Source Server (Subcontract)
          </h2>
          <p>
            SCIT Labs personnel contributed to the design, development and
            management of a next generation medical informatics system called
            the UMLS Knowledge Source Server (UMLSKS) that allowed users to
            search and retrieve information from various medical vocabularies
            contained in the Unified Medical Language System (UMLS). As part of
            this work we designed a fast, efficient and flexible system using
            Services Oriented Architecture (SOA) and Web2.0 technologies to
            accommodate the complex and varying needs of the UMLS user
            community.
          </p>
        </PageWrap>
        <PageWrap>
          <h2>
            US Army CERDEC: SCIT-DNS: A Domain Name Server (DNS) Protection
            Technique
          </h2>
          <P>
            The Domain Name System (DNS) is a critical part of the internet
            infrastructure. Because of DNS, users do not need to remember the IP
            address of the website, but can access them by using an easy to
            remember domain name (URL). DNS was designed for a trusted
            environment. Today’s malicious activity has reduced the
            trustworthiness of the DNS mapping.
          </P>
          <P>
            DNSSEC has been proposed as a secure alternative, but is not suited
            for all environments. There are key management and political
            constraints to DNSSEC adoption, and DNSSEC adoption rates ave very
            low. In the tactical environment DNSSEC implementations are
            particularly challenging. In this project we propose an alternative
            approach that has the operational convenience of DNS and near DNSSEC
            trustworthiness.
          </P>
          <P>
            In our prior work we have developed SCIT technology that mitigates
            malware type of attacks. We have built an Authoritative Only DNS
            server based on this technology. In the current project we have
            extended our previous work to a recursive DNS server. We have
            augmented the SCIT-DNS server so that attacks like cache poisoning
            can also be handled by the new solution. Reflecting our general
            augmentation approach to cover more attacks, we call our new
            approach Hybrid SCIT – DNS.
          </P>
          <P>
            This project was supervised and monitored by CERDEC and has
            application in the Army’s tactical environment.
          </P>
        </PageWrap>
      </Layout>
    )}
  />
)

export default ProjectsPage
